import * as THREE from "three";
import Experience from "../Experience.js";
import Interlocutors from "../World/Interlocutors.js";

export default class Networking {
  constructor() {
    this.experience = new Experience();
    this.user = this.experience.user;
    this.canSendEmbodiment = false;
    this.interlocutors = new Interlocutors();

    // First, fetch username and color from the server
    this.initializeUser()
      .then(({ username, color }) => {
        this.user.parameters.userName = username;
        this.user.parameters.color = color;

        console.log(`Received username: ${username}, color: ${color}`);

        // Now create the WebSocket connection
        this.socket = new WebSocket("wss://brahma.xrss.org:8080");

        this.socket.onopen = () => {
          console.log("WebSocket connection established");
          this.sendInitialData(); // Send initial data when the connection opens
          // Enable embodiment sending after 1 second
          setTimeout(() => {
            this.canSendEmbodiment = true;
          }, 2000);
        };

        this.socket.onmessage = (event) => {
          if (event.data.length > 1) {
            this.receiveEmbodiments(event.data);
          }
        };

        this.socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          console.log("ReadyState:", this.socket.readyState);
        };

        this.socket.onclose = (event) => {
          console.log("WebSocket connection closed:", event.reason);
        };
      })
      .catch((error) => {
        console.error("Error initializing user:", error);
      });
  }

  // Method to fetch username and color from the server
  async initializeUser() {
    try {
      const response = await fetch(
        "https://brahma.xrss.org:8080/uniqueUsernameAndColor"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch username and color");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }

  sendInitialData() {
    const initData = {
      name: this.user.parameters.userName,
      color: this.user.parameters.color,
    };

    // Send the initial user data as a string over WebSocket
    this.socket.send(JSON.stringify(initData));
    console.log("Initial user data sent:", initData);
  }

  sendEmbodiment(HMD, LController, RController) {
    console.log("sending ", HMD.toArray());
    const data = {
      name: this.user.parameters.userName,
      color: this.user.parameters.color,
      HMDPosition: HMD.toArray(),
      LController: LController.toArray(),
      RController: RController.toArray(),
    };

    // Send the data as a string over WebSocket
    if (this.canSendEmbodiment) {
      this.socket.send(JSON.stringify(data));
    } else {
      console.log("Cannot send embodiment data yet");
    }
  }
  receiveEmbodiments(data) {
    console.log("Raw data received from server:", data);
    try {
      const interlocutorsData = JSON.parse(data);
      console.log("Parsed interlocutor data:", interlocutorsData);

      interlocutorsData.forEach((interlocutor) => {
        try {
          console.log(`Processing interlocutor: ${interlocutor.name}`);
          if (interlocutor.name === this.user.parameters.userName) {
            console.log(
              "Skipping current user embodiment because it has my username",
              interlocutor
            );
            return;
          }

          if (!this.interlocutors.containsEmbodiment(interlocutor.name)) {
            console.log(
              `Instantiating new embodiment for ${interlocutor.name}`
            );
            this.interlocutors.instantiateEmbodiment(
              interlocutor.name,
              new THREE.Color(parseInt(interlocutor?.color, 16))
            );
          }

          if (this.interlocutors.containsEmbodiment(interlocutor.name)) {
            if (
              interlocutor.HMDPosition &&
              interlocutor.LController &&
              interlocutor.RController
            ) {
              console.log(`Updating positions for ${interlocutor.name}`);
              const HMDMatrix = new THREE.Matrix4().fromArray(
                interlocutor.HMDPosition
              );
              const LControllerMatrix = new THREE.Matrix4().fromArray(
                interlocutor.LController
              );
              const RControllerMatrix = new THREE.Matrix4().fromArray(
                interlocutor.RController
              );

              this.interlocutors.updateEmbodiment(
                interlocutor.name,
                HMDMatrix,
                LControllerMatrix,
                RControllerMatrix
              );
            } else {
              console.warn(`Incomplete data for ${interlocutor.name}:`, {
                HMDPosition: interlocutor.HMDPosition,
                LController: interlocutor.LController,
                RController: interlocutor.RController,
              });
            }
          }
        } catch (error) {
          console.error(
            `Error processing interlocutor ${interlocutor.name}:`,
            error
          );
        }
      });
    } catch (error) {
      console.error("Error parsing interlocutor data:", error);
    }
  }
}
