import * as THREE from "three";
import Experience from "../..//Experience.js";
export default class TimeScrubber {
  constructor() {
    this.experience = new Experience();
    this.makeTimeScrubberBack();
    this.makeTimeScrubberButtons();
  }
  makeTimeScrubberBack() {
    const timeScrubberBackGeometry = new THREE.PlaneGeometry(1.5, 3);
    const timeScrubberBackMaterial = new THREE.MeshBasicMaterial({
      color: 0x969696,
      side: THREE.DoubleSide
    });
    const timeScrubberBack = new THREE.Mesh(timeScrubberBackGeometry, timeScrubberBackMaterial);
    timeScrubberBack.rotation.x = Math.PI / 2;
    timeScrubberBack.position.set(-5, 0.5, 0);
    this.experience.scene.add(timeScrubberBack);
  }
  makeTimeScrubberButtons() {
    const timeScrubberButtonGeometry = new THREE.BoxGeometry(0.4, 0.05, 0.4);
    const timeScrubberButtonMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000
    });
    const timeScrubberPlayButton = new THREE.Mesh(timeScrubberButtonGeometry, timeScrubberButtonMaterial);
    timeScrubberPlayButton.position.set(-4.7, 0.5, 0);
    this.experience.scene.add(timeScrubberPlayButton);
    const timeScrubberBackButton = new THREE.Mesh(timeScrubberButtonGeometry, timeScrubberButtonMaterial);
    timeScrubberBackButton.position.set(-4.7, 0.5, -1);
    this.experience.scene.add(timeScrubberBackButton);
    const timeScrubberForwardButton = new THREE.Mesh(timeScrubberButtonGeometry, timeScrubberButtonMaterial);
    timeScrubberForwardButton.position.set(-4.7, 0.5, 1);
    this.experience.scene.add(timeScrubberForwardButton);
  }
}
