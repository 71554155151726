import * as THREE from "three";
import Experience from "../Experience.js";
import Environment from "./Environment.js";
import Floor from "./Floor.js";
import Stars from "./Stars.js";
import Geo from "./Geo.js";
import Geo2 from "./Geo2.js";
import Photospheres from "./DataRepresentations/Photospheres.js";
import TimeScrubber from "./TimeScrubber/TimeScrubber.js";
import Raycastable from "../UI/Raycastable.js";
//import Grabbable from "../UI/Grabbable.js";
export default class World {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.floor = new Floor();
    this.scene.add(new THREE.AxesHelper());
    // Wait for resources
    this.resources.on("ready", () => {
      // console.log("resources ready"); // used to be used to debug when or if resources were ready
      this.stars = new Stars();
      this.geo = new Geo();
      this.geo2 = new Geo2();
      this.environment = new Environment();
      this.photospheres = new Photospheres();
      this.TimeScrubber = new TimeScrubber();
      // const timeScrubberMockupMaterial = new THREE.MeshLambertMaterial({
      //   map: this.resources.items.timeScrubberMockup,
      //   // transparent: true,
      // });
      const redMaterial = new THREE.MeshLambertMaterial({
        color: 0xff0000,
        transparent: true,
        // side: THREE.DoubleSide,
      });

      // Obsolete code for the time scrubber
      // const grabbable = new TimeScrubber(
      //   new THREE.BoxGeometry(0.4, 0.1, 0.05),
      //   [
      //     redMaterial,
      //     redMaterial,
      //     redMaterial,
      //     redMaterial,
      //     timeScrubberMockupMaterial, // front side
      //     redMaterial,
      //     redMaterial,
      //   ],
      //   "grabbable"
      // );

      // console.log(grabbable.scale);
      // grabbable.position.set(0, 0.5, 0);
      // this.scene.add(grabbable);

      // Define the floor colors
      const floorColors = [0x008080, 0xffff00, 0xf5f5dc]; // teal, yellow, beige

      // Define the floor heights, skipping index 0
      const floorHeights = [-5, -10.1, -15];

      this.planeGeometry = new THREE.PlaneGeometry(10, 10);

      // Loop through the heights and add the colored planes
      for (let i = 0; i < floorHeights.length; i++) {
        const material = new THREE.MeshBasicMaterial({
          color: floorColors[i],
          side: THREE.DoubleSide,
        });
        const plane = new THREE.Mesh(this.planeGeometry, material);

        // Position the plane at the appropriate height
        plane.position.y = floorHeights[i];

        // Rotate the plane to make it horizontal
        plane.rotation.x = -Math.PI / 2;

        // Add the plane to the scene
        this.experience.scene.add(plane);
      }
    });
  }
  update() {} //unused
}
