import * as THREE from "three";
import Experience from "../../Experience.js";
import Agent from "./Agent.js";
import RoundedBoxGeometry from "../../Utils/RoundedBoxGeometry.js";
import { ConeGeometry } from "three";
// import Raycastable from "../UI/Raycastable.js";

// import Calloutable from "../UI/Calloutable.js";
// import { agentStates } from "../Data/Agents/agentStates.js";
// import { agentIDs } from/ "../Data/Agents/ListOfAgents.js";
// import { linksData as linksData } from "../Data/Links/LinksData.js";

export default class Agents extends THREE.Group {
  constructor() {
    // this is a THREE.Group that we're going to add all the agents to
    super();
    this.experience = new Experience();
    this.projection = this.experience.world.geo.projection;
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.geometry = new ConeGeometry(1, 2, 32);
    this.geometry.scale(0.05, 0.05, 0.05);
    this.material = new THREE.MeshLambertMaterial({
      color: 0xff0000,
      wireframe: true,
    });
    this.agents = new Set();

    this.addAgents();
    // new debug folder, a slider
    // this.debugFolder = this.debug.ui.addFolder("Agents");
  }
  async addAgents() {
    try {
      const response = await fetch("StartingAgents.json", {
        headers: {
          Accept: "application/json",
        },
      });
      const startingAgentsData = await response.json();
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("The response is not JSON");
      }
      const existingAgents = new Set();

      startingAgentsData.data.forEach((agent) => {
        const scaling = this.experience.world.geo.scaling;
        const id = agent.agent_id;
        const lat = agent.lat;
        const lng = agent.lng;

        // Create a unique key for each agent based on its latitude and longitude
        const agentKey = `${lat},${lng}`;

        // Check if an agent with the same lat/lng already exists
        if (existingAgents.has(agentKey)) {
          return; // Skip creating a new agent
        }

        // Add the current agent's lat/lng to the set
        existingAgents.add(agentKey);

        const [x, z] = this.projection.proj([lat, lng]);
        const color = Math.random() * 0xffffff;

        // new material
        const material = new THREE.MeshLambertMaterial({
          color: 0x00ff00,
          wireframe: false,
        });

        const calloutableAgent = new Agent(this.geometry, material, id);
        calloutableAgent.position.set(x * scaling.x, 0.4, -z * scaling.z);
        calloutableAgent.rotation.x = Math.PI;

        this.scene.add(calloutableAgent);
      });
    } catch (error) {
      console.error("Error adding agents", error);
    }
  }
}
